<template>
  <div class="aboutus">
    <!-- <div class="title">
      <el-divider content-position="center">个人简介</el-divider>
      <p><el-tag>xxxx大学</el-tag><el-tag>本科</el-tag></p>
    </div> -->
    <el-card
      class="box-card animate__animated animate__fadeInDown"
      style="margin-bottom: 20px; margin-top: -120px"
    >
      <el-scrollbar
        :native="false"
        wrapStyle=""
        wrapClass=""
        viewClass=""
        viewStyle=""
        noresize="false"
        tag="section"
      >
        <div style="height: 550px">
          <div class="item text">
            <el-container>
              <el-header height="50px"><b class="b-size" height>个人信息</b></el-header>
              <el-main>
                <Col key1='姓名' value1='李斯特' key2='性别' value2='男'/>
                <Col key1='毕业院校' value1='广州大学华软软件学院' key2='专业' value2='游戏设计与制作'/>
                <Col key1='工作职业' value1='Java开发工程师' key2='电子邮件' value2='158040416@qq.com'/>
              </el-main>
            </el-container>
            <!-- <el-container>
             
              <el-main>
               <el-row :gutter="40">
              <el-col :span="8">
                <div class="grid-content">
                  于2011.07月毕业于<span class="large">广州大学华软软件</span
                  >，专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业专业
                </div>
              </el-col>
              <el-col :span="8">
                <div class="grid-content">
                    测试是生生世世是是谁死谁说
                </div>
              </el-col>
              <el-col :span="8">
                <div class="grid-content">
                  工作中比较自律，对待工作认真负责，喜欢<span class="large"
                    >不断学习</span
                  >来提升自己。希望能找到一群志同道合的人一起工作，不断进步和成长。
                </div>
              </el-col>
            </el-row>
              </el-main>
            </el-container> -->

            <el-container>
              <el-header height="50px"><b class="b-size" height>工作经验</b></el-header>
              <el-main>
                <oneCol key1='2023/05---2025/01' value1='广州儒宝科技发展有限公司'/>
                <oneCol key1='2020/07---2023/04' value1='点金互联网科技（广州）有限公司'/>
                <oneCol key1='2019/04---2020/06' value1='广州地合云图科技有限公司'/>
                <oneCol key1='2017/10---2019/03' value1='广东昱辉通信技术有限公司'/>
                <oneCol key1='2015/11---2017/09' value1='广州灵讯科技有限公司'/>
              </el-main>
              <!-- <el-footer height="50px">Footer</el-footer> -->
            </el-container>

            <el-container>
              <el-header height="50px"><b class="b-size" height>项目经验</b></el-header>
              <el-main>
                <oneCol key1='万言国学（课程直播电商） & 五福堂店铺系统（店铺入驻+分账分佣）' value1='1. 集线上课程、文创产品销售、店铺商家、分佣体系的一体化多端协同的文创国学推广项目
2. 目前平台总用户数70+W人，日活用户2W+，平台日均线上订单量3500+'/>
                <oneCol key1='矿天下 | 矿业资源社交交易平台' value1='1.  面向矿主、投融资方、个人矿产品销售的集B2B和B2C的社交交易平台，提供矿产品交易、IM交流、专家咨询等核心功能，付费会员5W+'/>
                <oneCol key1='GIS数字化项目管理平台' value1='地合云图平台致力于为乡村振兴提供底层技术支持,首创“GIS+实景三维+VR全景”的跨学科技术支持方案,应用于村庄级国土空间规划，推进农村产业数字化，提高村庄基层治理和宣传推广的效率'/>
                <oneCol key1='全景VR生成式管理平台' value1='地合云图提供给C端和B端用户使用的全景式生成VR平台，包括全景图片合成，全景场景和作品的生成，全景素材、全景作品可视化编辑界面'/>
                <oneCol key1='矿天下APP' value1='点金互联网对外展示的交互型app,包括矿权买卖、转让、融资、投资、矿业头条、矿业物流等内容，实现客户获取最新行业资讯用户买卖信息，并提供一套符合当前行业规划的解决方法，提供各种便利性工具供客户使用，解决矿业行业封闭的环境造成的问题'/>
                <oneCol key1='点金互联网业务管理系统' value1='供公司工作人员使用，根据业务流程、审核流程、业务数据、品牌宣传、头条等开发各种模块，与线下流程实现一样，并为app系统提供各种业务数据和支撑'/>
                 <oneCol key1='矿天下微信CRM小程序' value1='矿天下小程序是app与管理系统的业务衍生，为方便客户和工作人员在微信上快速浏览而开发的，在其原有功能上加上了联系人等方便业务的模块'/>
                <oneCol key1='点金数据抓取系统' value1='定期获取最新矿业资讯、矿权信息，提供爬取状态监控数据，故障失败报警信息'/>
                <oneCol key1='电信光宽端到端运营支撑平台' value1='为解决运维支撑系统外接接口多，各厂家系统在排障过程中需跨系统频繁切换，花费时间长，不能高效处理工单，综调、ANISS、163等系统外接频繁、混乱，使得接口维护成本高、系统运转不稳定，系统故障频次、时长居高不下这一问题。
通过全面梳理网络建设、客户装维支撑等各个环节，通过整合专业网管，从光网建设质量管控、装维支撑工具、故障诊断手段、客户接入网预检预修等方面入手，建设端到端智能运营系统，为全网光宽带端到端运营能力系统的改造提供依据和统一查询排障平台。'/>
                <oneCol key1='天翼高清支撑平台' value1='为天翼高清提供4A平台认证，提供光宽部分对端功能的业务数据。
解决天翼高清系统与光宽运维系统的功能对接，鉴于光宽目前功能模块过多，若继续以子系统模式为外系统提供服务将会加大系统间的耦合，造成以后维护和重构的困难，接着此契机，经过与天翼高清技术负责人的商议，决定通过微架构的形式来提供功能'/>
                <oneCol key1='福柏科技运营系统' value1='福柏机械设备展示网站含有多种类设备以及行业新闻、技术支持等信息内容，为有效管理这些展示数据以及数据统计，为此营应运而生的系统，集数据统计与数据管理的公司内部系统，减少原来的静态网页因修改公司产品而带来的麻烦操作'/>
                <oneCol key1='职校教育管理系统' value1='在职务教育等多模块管理的当下，为更好管理职务环境里各种人员以及需求的前提下，职校教育管理系统为此应运而生。
                  为职业教育管理方提供更好的课程学员管理，一系列后台管理工具，清晰展示各职务部门的管理课程和模块，提供各部门查询的便利性和管理性。'/>
                <oneCol key1='智慧校园无忧借平台' value1='该系统主要面向职校图书馆的服务实体对象开发，适合中小型校园图书馆使用，主界面中通过菜单实现不同模块的功能，包括系统管理员，书籍信息管理、图书属性管理和分类功能。
为校园图书馆，学生借书还书阅读提供一系列的流程服务，方便校方管理员管理图书馆，统计各种类型书籍在校园中的受欢程度，种类稀少的书籍库存，并对此执行相关采购工作和推广阅读工作。'/>
              </el-main>
              <!-- <el-footer height="50px">Footer</el-footer> -->
            </el-container>

            <el-container>
              <el-header height="50px"><b class="b-size" height>教育经历</b></el-header>
              <el-main>
                <Col key1='2008/09---2011/07' value1='广州大学华软软件学院' key2='院系' value2='游戏系'/>
              </el-main>
              <!-- <el-footer height="50px">Footer</el-footer> -->
            </el-container>



            <el-container>
              <el-header height="50px"><b class="b-size" height>自我评价</b></el-header>
              <el-main>
                <div style="block:inline">
                <p style="background-color: #f9fafc;">·良好的团队沟通能力，善于表达问题，解决问题。</p>
                <p style="background-color: #f9fafc;"> ·能快速上手新项目和新技术，适应各种的工作环境，对工作认真负责。</p>
                <p style="background-color: #f9fafc;">·敢于面对困难和挑战，并具有很好的分析与解决问题的能力。</p>
                <p style="background-color: #f9fafc;">·对编程有浓厚的兴趣，喜欢砖研新技术。</p>
                <p style="background-color: #f9fafc;">·有较强的自学能力，逻辑思维能力。</p>
                </div>
              </el-main>
              <el-footer height="50px"><b class="b-size" height>谢谢浏览到此的朋友！</b></el-footer>
            </el-container>

            <!-- <el-container>
              <el-aside width="200px">Aside</el-aside>
              <el-main>Main</el-main>
            </el-container> -->
          </div>
        </div>
      </el-scrollbar>

      <div class="topMask square"></div>
      <div class="topMask circular"></div>
    </el-card>
  </div>
</template>
<style>
.b-size{
  font-size: 1rem;
}
.aboutus .grid-content.line {
  border-right: 1px solid #ddd;
  height: 400pxpx;
}
.aboutus .el-card__header {
  background: #545c64;
}
.aboutus .el-card__body {
  padding: 50px 20px;
}
.aboutus .el-timeline-item__wrapper {
  top: -8px;
}
.aboutus .title p .el-tag {
  margin: 0px 5px;
  cursor: pointer;
}
</style>
<style scoped>
.aboutus {
  font-size: 14px;
  text-align: left;
  padding: 0px 100px;
  background: url(../../assets/logo.jpeg) no-repeat;
  height: 76.3%;
  background-size: 100% 100%;
  animation: bg infinite 100s linear alternate;
  padding-top: 15rem;
}
.intro {
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.text {
  font-size: 14px;
  text-align: left;
  line-height: 30px;
}
.box-card {
  position: relative;
  opacity: 0.5;
}
.item {
  /* display: inline-block; */
  margin: 30px 50px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.clearfix span {
  color: #fff;
  font-weight: bold;
}
.title p {
  color: #8c8888;
  font-size: 15px;
  margin-bottom: 80px;
  text-align: center;
}
.grid-content .large {
  font-size: 16px;
  color: #409eff;
  font-weight: bold;
}
.topMask {
  width: 100px;
  height: 100px;
  background-color: rgba(68, 138, 255, 0.2);
  transform: rotate(45deg);
  position: absolute;
}
.square {
  border-radius: 5px;
  top: 0px;
}
.circular {
  border-radius: 50%;
  top: 80px;
  left: 80%;
  background-color: rgba(255, 208, 75, 0.2);
}
@keyframes bg {
  0% {
    background-size: 110% 130%;
  }
  10% {
    background-size: 111% 131%;
  }
  20% {
    background-size: 112% 132%;
    background-position: bottom;
  }
  30% {
    background-size: 113% 133%;
  }
  40% {
    background-size: 114% 134%;
  }
  50% {
    background-size: 115% 135%;
    background-position: left;
  }
  60% {
    background-size: 116% 136%;
  }
  70% {
    background-size: 117% 137%;
  }
  80% {
    background-size: 118% 138%;
    background-position: right;
  }
  90% {
    background-size: 119% 139%;
  }
  100% {
    background-size: 120% 140%;
  }
}
.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 50px;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  /* line-height: 160px; */
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

</style>
<script>
import Col from "@/components/AboutUs/Col.vue";
import oneCol from "@/components/AboutUs/oneCol.vue";
export default {
  components: {Col,oneCol},
  name: "AboutUs"
};
</script>